<template>
    <div class="cont">
        <h1>FOOOOOOOOOOOT</h1>
    </div>
</template>

<script>

</script> 

<style scoped>
    .cont{ width: 90%; border: 2px solid; margin: auto; background-color: #999}

</style>