import { createRouter, createWebHistory } from 'vue-router'
//import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import TarzanView from '@/views/TarzanView.vue'
import ZorroView from '@/views/ZorroView.vue'

const routes = [
  {
    path: '/',
    redirect:  { name: "Orzoway" }
  },
  {
    path: '/A/:id',
    name: 'Orzoway',
    component: () => import(/* webpackChunkName: Orzoway */ '@/views/HomeView.vue')//HomeView
  },
  {
    path: '/B/:id',
    name: 'Sandokan',
    component: () => import(/* webpackChunkName: Sandokan */ '@/views/AboutView.vue')//AboutView
  },{
    path: '/C/:id',
    name: 'Tarzan',
    component: () => import(/* webpackChunkName: Tarzan */ '@/views/TarzanView.vue')//TarzanView
  },
  {
    path: '/D/:id',
    name: 'El Zorro',
    component: () => import(/* webpackChunkName: Zorro */ '@/views/ZorroView.vue')//ZorroView
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
