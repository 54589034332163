<template>
  <div class="plantilla">
  <div class="head">
       <select class="form-select sel1" v-model="selected" v-on:change="changeUrl()">
          <option v-for="itm in obj" :key="itm.id">{{ itm.modelo }}</option>
       </select> 
       <img class="img" src="@/assets/logo.png">
  </div>
    {{ id }}
  <nav class="router">
    <router-link :to="{name:'Orzoway', params:{id: 1}}">Orzoway</router-link> |
    <router-link :to="{name:'Sandokan', params:{id: 2}}">Sandokan</router-link>|
    <router-link :to="{name:'Tarzan', params:{id: 3}}">Tarzan</router-link>|
    <router-link :to="{name:'El Zorro', params:{id: 4}}">El Zorro</router-link>|
  </nav>

  <router-view/>

  <FootComp class="foot"/>
  </div>
</template>

<script>
import {defineComponent, onBeforeUpdate, ref,} from 'vue';
import FootComp from '@/components/FootComp'
import { useRouter } from 'vue-router'

export default defineComponent({
  components:{
    FootComp,
  },
  setup(){
    const router = useRouter();
    let selected = ref("");
    let id = ref(0);
   // onBeforeUpdate(()=>{id.value = router.params.id;})//No funciona
    
    let obj = [{"id":1,"modelo": "Orzoway"},{"id":2,"modelo": "Sandokan"},{"id":3,"modelo": "Tarzan"},{"id":4,"modelo": "El Zorro"}];

    let changeUrl = () => {router.push({name: selected.value})};

    return {obj, id, selected, changeUrl, router, FootComp}
  }
  });

</script>
<style lang="scss" scoped>

$grey: #9f9f9f;
.plantilla{background-color: #b0b0b0; text-align: center; margin: autp; border: solid 1px} 
body{background-color: #797979; }
.head{ width: 90%; border: solid 1px; background-color: rgb(171, 195, 195); height: 100px; margin: 0 auto 20px  ;position: sticky; top: 5px; border-radius: 10px;}
.sel1{width: 300px; margin: 10px 20px; }
.img{ width: 90px; position: absolute; right: 20px; top: 6px}
.router{position: fixed; right: 160px; top: 6px }
.foot{ position: sticky; bottom: 5px}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
  
  a {
    font-weight: bold;
    color: #959595;

    &.router-link-exact-active {
      color: #4250b9;
    }
  }
}

</style>
